 .main-footer{
            padding: 70px ;
            display: flex;
            justify-content: space-around;
            background-color: #000000;

           
        }
        .main-footer ul{
            list-style: none;
        }
        .aboutBetaCode{
            color: #ffffffcc;
            text-decoration: none;
            max-width:50%;
        }
        .main-footer h1{
            font-size: 22px;
            line-height: 117%;
            color: #ffffff;
            margin-bottom: 10px;
            font-weight: 500;
        }
        .main-footer h2{
            color: #ffffff;
            font-weight: 500;
        }
        .main-footer ul li a{
            color: #ffffffcc;
            text-decoration: none;
        }
        footer{
            background-color: #000000;
            border-top: 1px solid #fff;
            font-size: 17px;
            padding: 15px 5px;
            color: #ffffff;
            text-align: center;
         
        }
        footer a{
            text-decoration: none;
            color: #ffffff;
        }
        .designPowered {
            color:#6EB981 ;
            font-size: 17px;
            margin-top: 5px;
        }
        .contact-details{
            list-style: none;
            margin: 10px 0;
        }
        .contact-details li a{
            text-decoration: none;
            color: #f1f1f1;
        }
        .contact-details .fa{
            color: #f1f1f1;
            margin-right: 10px;
        }
        .sociallogos{
            padding: 20px 0;
        }
        .sociallogos .logobox a{
            padding: 0 10px ;
                text-decoration: none;
                color: #ffffff;
                font-size: 22px;
        }
        .icons-style{
            width: 40px;
            height: 40px;
        }
        .com ul li{
            padding: 5px 0;
         
        }
        @media only screen and (max-width: 749px){
            .main-footer{
                padding: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            .info{
                padding: 20px 0;
            }
               .aboutBetaCode{
            /* color: #ffffffcc; */
            /* text-decoration: none; */
            /* max-width:100%; */
        }
        }
        @media (max-width: 480px){
            .main-footer{
                grid-template-columns: 1fr;
            }
            .sociallogos{
                padding: 20px 0;
            }
            .com{
                padding: 20px 0;
            }
        }
