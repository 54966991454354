
.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 2rem; */
  /* top: 0; */
  /* overflow: hidden; */
  /* position: fixed; */
  width: 100%;
  background: #000000;
  background: radial-gradient(
    circle,
    #000000 0%,
    rgba(15, 23, 42, 1) 90%
  );
  /* background-color: #333; */
}
.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  align-items: center;
  
}
.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}
.n-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
  color: white;
}

.n-list > ul :hover {
  color: var(--orange);
  cursor: pointer;
}

.n-button {
  flex: 2;
}

@media screen and (max-width: 480px) {
  .n-list {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-slider-thumb {
}
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 50px;
}

.GlowingButton {
  padding: 10px 20px;
  border: none;
  font-size: 17px;
  color: #fff;
  border-radius: 7px;
  /* letter-spacing: 4px; */
  font-weight: 700;
  /* text-transform: uppercase; */
  transition: 0.5s;
  transition-property: box-shadow;
   box-shadow: 0 0 5px #a672dc, 0 0 5px #a672dc, 0 0 5px #a672dc,
    0 0 100px #a672dc;
}

.GlowingButton {
  background: transparent;
  /* box-shadow: #0f172a; */
}

.GlowingButton:hover {
  box-shadow: 0 0 5px #a672dc, 0 0 25px #a672dc, 0 0 50px #a672dc,
    0 0 100px #a672dc;
}

    @media (max-width: 375px){
.GlowingButton {
  padding: 5px 10px;
  border: none;
  font-size: 12px;
  color: #fff;
  border-radius: 7px;
  /* letter-spacing: 4px; */
  font-weight: 700;
  /* text-transform: uppercase; */
  transition: 0.5s;
  transition-property: box-shadow;
   box-shadow: 0 0 5px #a672dc, 0 0 5px #a672dc, 0 0 5px #a672dc,
    0 0 100px #a672dc;
}
.n-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  align-items: center;
  
}
        
        }
    @media (max-width: 800px){
.GlowingButton {
  padding: 10px 20px;
  border: none;
  font-size: 17px;
  color: #fff;
  border-radius: 7px;
  /* letter-spacing: 4px; */
  font-weight: 700;
  /* text-transform: uppercase; */
  transition: 0.5s;
  transition-property: box-shadow;
   box-shadow: 0 0 5px #a672dc, 0 0 5px #a672dc, 0 0 5px #a672dc,
    0 0 100px #a672dc;
}
.n-name {
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  align-items: center;
  
}
        
        }