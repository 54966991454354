.App {
  /* text-align: center; */
}

.App-logo {
  height: 70vmin;
  pointer-events: none;
}
.screen1-style{
height: 100vh;
}
.container-text-img {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}
.text-unleash {
  color: rgb(191, 129, 250);
}
.container-main{
  min-height: 100vh;
  width: 100%;
}
.Screen3background {
  background: #fff;
  background: #5ece91;
  /* rgb(123, 88, 170)/ */
  background: radial-gradient(circle, #b66231 0%, #2a1111 90%);

  /* background-color: #cfe2ff; */
  /* background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%); */
}
.Screen2background {
  background: #fff;
  background: #5ece91;
  /* rgb(123, 88, 170)/ */
  background: radial-gradient(circle, #b66231 0%, #2a1111 90%);

  /* background-color: #cfe2ff; */
  /* background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%); */
}

.text-gradient {
  /* Set the background color */
  background: linear-gradient(to right, #ff8a00 0%, #dd4c4f 100%);
  /* Mask the color to the text, and remove the rest  */
  -webkit-background-clip: text;
  /* Make the text fill color value transparent so the masked background color comes through */
  -webkit-text-fill-color: transparent;
  max-width: 100%;

}
.description-text {
  /* Set the background color */
  background: linear-gradient(to right, #ae77e6 0%, #000000 100%);
  /* Mask the color to the text, and remove the rest  */
  -webkit-background-clip: text;
  /* Make the text fill color value transparent so the masked background color comes through */
  -webkit-text-fill-color: transparent;
}
.screen-3-gradient {
  /* Set the background color */
  background: linear-gradient(to right, #ee7b6c 0%, #fff 100%);
  /* Mask the color to the text, and remove the rest  */
  -webkit-background-clip: text;
  /* Make the text fill color value transparent so the masked background color comes through */
  -webkit-text-fill-color: transparent;
  /* max-width: 100%; */
  
}


.text-width{
  max-width: 100%;
}
.description-text {
  /* Set the background color */
  background: linear-gradient(to right, #ae77e6 0%, #000000 100%);
  /* Mask the color to the text, and remove the rest  */
  -webkit-background-clip: text;
  /* Make the text fill color value transparent so the masked background color comes through */
  -webkit-text-fill-color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}
.screen1Font{
  font-size: 80px;
}
@media screen and (max-width: 800px) {

  .App-logo {
    width: 100%;
  }
  .container-text-img{
      /* display: inline; */
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  }
  .screen-3-gradient{
  /* Set the background color */
  background: linear-gradient(to right, #ee7b6c 0%, #fff 100%);
  /* Mask the color to the text, and remove the rest  */
  -webkit-background-clip: text;
  /* Make the text fill color value transparent so the masked background color comes through */
  -webkit-text-fill-color: transparent;
  /* max-width: 100%; */
  /* width: 100%; */
  
}
.header-text{
  font-size: 80px;
}
.screen1-style{
background-color: #000000;
height: auto;
}
.text-width{
  max-width: 100%;
  /* word-wrap: break-word; */
}
.screen1Font{
  font-size: 60px;
}
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Footer css */

       